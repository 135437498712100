import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import Layout from "../../components/layout";
import Service from "../../components/services/service-tile";
import serviceLayoutStyles from "../../scss/layouts/service_content.module.scss";
import headerStyles from "../../scss/modules/text_header.module.scss";
import ServicesCta from "../../components/cta/cta_service-detail";
import ctaImage from "../../images/Interested_in_Technical_Serivces.jpg";


export default ({ data }) => {
  const services = data.allMarkdownRemark;
  const pageData = data.markdownRemark;

  return (
    <Layout>
      <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.intro }
        pathname={pageData.fields.slug}
      />
       <div className={headerStyles.text_header__header_green}>
          <div className={headerStyles.text_header__header_inner}>
            <h1>{ pageData.frontmatter.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
          </div>
        </div>

      <div className={serviceLayoutStyles.service_content}>
        <div className={serviceLayoutStyles.service_content__main}>
          <div className={serviceLayoutStyles.service_content__banners}>
            <Img fixed={data.image1.childImageSharp.fixed} />
            <Img fixed={data.image2.childImageSharp.fixed} />
            <Img fixed={data.image3.childImageSharp.fixed} />
          </div>
          <div className={serviceLayoutStyles.service_content__inner}>

            { services.nodes.map(function(service, index) {
            return <Service title={service.frontmatter.title}
                      subtitle={service.frontmatter.subTitle}
                      summary={service.frontmatter.summary}
                      image={service.frontmatter.featuredImage} />
                }) }
          </div>
        </div>
      </div>
      <ServicesCta title={"Technical"}
                   content={"Get in touch with one of our technical experts today."}
                   image={ctaImage}
                   />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    image1: file(relativePath: { eq: "services/Drawing_and_Technical_Services_Drawing.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 230, height: 153) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    image2: file(relativePath: { eq: "services/Drawing_and_Technical_Services_Flooding-crop.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 230, height: 153) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    image3: file(relativePath: { eq: "services/Drawing_and_Technical_Services_survey.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 230, height: 153) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
      }
      html
      fields {
        slug
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/services\\/technical\\/[a-z0-9\\\\-]+.md$/"}
      }
      sort: {
        fields: frontmatter___rank,
        order: ASC
      })
       {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
          rank
          summary
          subTitle
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 5000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`